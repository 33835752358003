import jamieImg from '../images/bioPics/Jamie.jpg';
import dakotaImg from '../images/bioPics/dakota.jpg';
import jennyImg from '../images/bioPics/jenny.jpg';
import rosalineImg from '../images/bioPics/rosaline.jpg';
import brentImg from '../images/bioPics/brent.jpg';
import maggieImg from '../images/bioPics/maggie.jpg';
import sheilaImg from '../images/bioPics/sheila.jpg';

export const BoardInfo = [
    {
        name: 'Jamie Otter',
        position: 'Board President',
        currentEmployment: 'School Social Worker for the Saginaw ISD',
        education: 'Associate’s Degree in Photographic Imaging from Lansing Community College Bachelor’s Degree in Social Work from Saginaw Valley State University Master’s Degree in Social Work from Michigan State University',
        info: 'When I am not working, I love to be around family and friends, hang out with my dogs, photograph nature, sew, and read.',
        image: jamieImg
    },
    {
        name: 'Maggie Garza',
        position: 'Vice President',
        currentEmployment: 'AVP of Operations at Jackson National ',
        education: 'Graduate of Sexton High School, Bachelors Degree in Business Management from Northwood University, MBA in progress from',
        info: 'Maggie is married and has three boys, ages 22, 16, and 12 years of age.  When she’s not working or spending time with her boys, she’s most likely playing sports (softball, bowling, or even her least favorite - golf!) with her husband TJ and/or close friends.  She loves Steelers football and traveling to warmer climates whenever possible! Giving back to the community is important to her and her family - with Volunteers of America and Big Brothers Big Sisters being two of several local charities they’ve been actively involved in over the years. Favorite Quote:  "Difficult roads often lead to beautiful destinations.”  - Zig Ziglar',
        image: maggieImg
    },
    {
        name: 'Dakota Anderson-White',
        position: 'Board Secretary',
        currentEmployment: 'Operations Associate at Jackson National ',
        info: 'He enjoys time with friends and family Favorite Quote; “Never Forget What You Are. The Rest Of World Will Not. Wear It Like Armor, And It Can  Never Hurt You.” By Tyrion Lannister',
        image: dakotaImg
    },
    {
        name: 'Jenny Metzmaker',
        position: 'Founder CEO',
        currentEmployment: 'MSU Federal Credit Union ',
        info: 'Her desire to be a Positive Mental Health Advocate comes from being an abuse and suicide survivor. Enjoys creating art and spending time with her loved ones. Favorite Quote; “Just keep swimming” By Dory',
        image: jennyImg
    },
    {
        name: 'Rosaline Kuiper-Price ',
        position: 'Assistant Director',
        currentEmployment: 'Has been an educator for the past 21 years: 16 years with the Lansing School District, and the last 5 years with Waverly Community Schools.',
        education: 'Education: Bachelors in Special Education from MSU Mastersin Rehabilitation Counseling from MSU in 2009.',
        info: 'Although opportunities to work in vocational rehabilitation presented themself, Roz chose to stay in the school setting as she loves working with youth. Her counseling skills transferred into the classroom as she specialized in working with students with emotional impairments. Roz is pleased to be a part of Positive Somebody where she can continue to help children develop positive coping skills through psychoeducational small group offerings. As a mother of 3, she has taught the coping skills offered in Positive Somebody to her own children and seen great benefits.',
        image: rosalineImg
    },
    {
        name: 'Brent Sorg',
        position: 'Member at Large',
        currentEmployment: 'Sergeant - Lansing Police Dept (21 yrs). Lansing Commons - Head Coach. Spring Arbor University - Mens Soccer Assistant Coach (2 yrs). ODP Soccer - Head Coach (14 yrs).',
        info: 'Enjoys biking, golfing, and cooking. Resident of East Lansing.',
        image: brentImg
    },
    
    {
        name: 'Sheila Nash',
        position: 'Member at Large',
        currentEmployment: 'Special Educator at the K-12 levels',
        info: 'Sheila Nash has been an educator for over 30 years. Her involvement in education has been as a special educator at the K-12 levels as well as a coach for academic and sports teams. For 16 years she was an active parent volunteer in a variety of school and extracurricular activities in the Lansing and East Lansing areas. She currently teaches and coaches in Lansing, MI. Much to her husband and students dismay, she has no plans to retire anytime soon. She continues to cheer on her adult children and her former students from the sidelines.',
        image: sheilaImg
    }
]
    