import React from 'react';
import '../css/homepage.scss';
import table from '../images/homepagePics/NewsletterAugust2024.png';
import jenny from '../images/bioPics/jenny.jpg';
import { Link } from 'react-router-dom';
import logo from '../images/logo.jpg';


const HomePage = () => {
    return (
        <div className="homepage">
            <section className="homepage__hero">
                <div>
                    <img src={table}></img>
                </div>
                <div>
                </div>
            </section>

            <section className="homepage__content">
                <div className="homepage__section">
                    <div className="home-container">
                        <div className="image-button">
                            <img src={jenny} className="jenny" />
                            <Link to="../aboutUs">
                                <button className="btn">More About Us</button>
                            </Link>
                        </div>
                        <p>
                            My name is Jenny Metzmaker, founder and CEO of Positive Somebody, a 501c3 public organization started to promote positive mental health by fostering healthy coping skills in children. Positive Somebody distributes Positive Packs to schools and other organizations. The packs include custom journals, pencils, stress reliever fidgets, breathe stones with a card, positive affirmation cards, bracelets, and worksheets on using the items. The goal of the Positive Packs is to help children learn how to cope positively to reduce negative coping actions such as depression, anxiety, addiction, and violence.
                        </p>
                    </div>
                </div>
                <div className="home-container">
                    <p className="home-container-paragraph">
                        Positive Somebody has donated more than 2000 Positive Packs to eight school districts in Michigan. Two hundred Positive Packs have been distributed to Holt High School’s counseling center to help with the aftermath of a student taking her life as well as ongoing bullying issues. Other school districts that have received Positive Packs include Laingsburg, Lansing, Merril, Saginaw, Waverly, Oxford, and more. Our current projects include creating an app where students are able to share with their teacher and counselors their emotions, write in their daily, and learn about positive coping. We are looking to partner with the Lansing Police Department to have Positive Packs carried in the police vehicles to distribute to children they encounter.
                    </p>
                    <div className="image-button">
                        <img src={logo} className="" width="250" height="160" />
                        <Link to="../programs">
                            <button id="programsLink" className="btn">Learn More</button>
                        </Link>
                    </div>
                </div>

                {/* <div className="homepage__section">
                    <h2>Our Services</h2>
                    <p>Describe the services you offer.</p>
                </div>
                <div className="homepage__section">
                    <h2>Contact Us</h2>
                    <p>Provide contact information or a contact form.</p>
                </div> */}
            </section>

            <footer className="homepage__footer">
            </footer>
        </div>
    );
}

export default HomePage;
